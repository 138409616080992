import React, { FC, ReactNode } from 'react'

interface ServerWrapperProps {
  isServer: boolean
  component: Function
  children: ReactNode
}
const ServerWrapper: FC<ServerWrapperProps> = ({
  isServer = true,
  component = React.Fragment,
  children = null
}) => {
  const Component = isServer ? React.Fragment : component
  return <Component>{children}</Component>
}
export default ServerWrapper
