import { types } from './types'

export const getTextBySlugAction = (props: any) => ({
  type: types['TEXTS/GET_DATA'],
  payload: props
})

export const setTextsDataAction = (props: any) => ({
  type: types['TEXTS/SET_DATA'],
  payload: props
})

export const clearTextsAction = () => ({
  type: types['TEXTS/SET_DATA'],
  payload: { data: {} }
})
