import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useCallback, useEffect, useState } from 'react'

import Alert from '@/components/Alert'

import NotificationProps from '@/types/Notification'

import styles from './notifications.module.scss'
import { useNotifications } from '@/bus/notifications'

const Notification: FC<NotificationProps> = ({
  UUID,
  type,
  message,
  delay = 5000
}) => {
  const [visible, setVisible] = useState(false)

  const { removeAfterDelay, remove } = useNotifications()
  const { t } = useTranslation('errors')

  const removeNotification = useCallback(() => {
    removeAfterDelay({ UUID, delay })
  }, [UUID, delay, removeAfterDelay])

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    setVisible(true)

    timeout = setTimeout(() => {
      setVisible(false)
      removeNotification()
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const closeNotification = useCallback(
    (UUID: string) => {
      remove({ UUID })
    },
    [remove]
  )

  return (
    <Alert
      className={clsx(styles.notification, {
        [styles['notification_visible']]: visible
      })}
      key={UUID}
      severity={type}
      handleClose={() => closeNotification(UUID)}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: t(`${message.toLowerCase().replace(/[\s]/g, '_')}`, message)
        }}
      />
    </Alert>
  )
}

export default Notification
