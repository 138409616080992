import { types } from './types'

interface TwoFactorProps {
  required: boolean
}

interface DataProps {
  two_factor?: TwoFactorProps
  id?: number
  name?: string
  balance?: object
}

interface InitialStateProps {
  loading: boolean
  loaded: boolean
  failed: boolean
  data: DataProps
}

const initialState: InitialStateProps = {
  loading: false,
  loaded: false,
  failed: false,
  data: {}
}

export function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case types['USER/ERRORS']:
    case types['USER/LOADING']:
    case types['USER/SET_DATA']: {
      return {
        ...state,
        ...action.payload
      }
    }

    case types['USER/CANCEL_2FA']: {
      const data = { ...state.data }
      delete data.two_factor
      return {
        ...state,
        data
      }
    }

    default:
      return state
  }
}
