interface RoutesProps {
  [key: string]: string
}
export const routes: RoutesProps = {
  HOME: '/',
  NETWORKS: '/networks',
  METHODS: '/#methods',
  OPPORTUNITIES: '/#opportunities',
  PARTNERS: '/partners-program',
  REGISTER: '/register',
  LOGIN: '/login',
  LOGIN_BY_ADMITAD: '/security/admitad/auth',
  RECOVERY: '/recovery',
  CHECK_EMAIL: '/check-email',
  USER_AGREEMENTS: '/oferta',
  PRIVACY_POLICY: '/privacy-policy',
  HELP: '/faq',
  BLOG: 'https://getuniq.me/blog/',
  CONTACTS: '/contacts',
  DOC: 'https://dev.getuniq.me/',
  AGENCY_RU: 'https://agency.getuniq.me/',
  AGENCY_EN: 'https://agency.getuniq.me/en',
  SUPPORT: '/:lang/tickets/new',
  POSTING: 'https://postingplus.getuniq.me/',
  TELEGRAM: 'https://t.me/getuniq',
  VK: 'https://vk.com/getuniqme',
  YOUTUBE: 'https://www.youtube.com/channel/UC4XLk6yFSluM_b6gFFtxBsQ',
  SERVICES: '/:lang/services',
  AUTH_LOGIN: '/auth/login',
  PROMISED_PAYMENT_TERMS: '/promised-payment-terms',
  REFUND: '/refund',
  OFERTA_SPARTA: '/oferta_sparta',
  TIKTOK_RULES: '/tik-tok/terms-of-use',
  404: '/404'
} as const
