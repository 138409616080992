import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, ReactFragment, ReactNode, useEffect, useState } from 'react'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Link from '@/components/Link'

import useMediaQuery from '@/hooks/useMediaQuery'
import useSessionStorage from '@/hooks/useSessionStorage'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Header.module.scss'
import { useMenu } from '@/bus/menu'
import { useUser } from '@/bus/user'
import { routes } from '@/config'

interface HeaderProps extends DefaultComponentProps {
  type?: string
  before?: ReactNode
  after?: ReactNode | ReactFragment
  children?: ReactNode
}

const Header: FC<HeaderProps> = ({
  className = '',
  type = 'default',
  before = null,
  after = null,
  children = null
}) => {
  const { getData, setData, removeData } = useSessionStorage()
  const { shown } = useMenu()
  const [isAnimated, setAnimated] = useState(false)
  const { isAuthorized } = useUser()
  const { t } = useTranslation()
  const isSmallScreen = useMediaQuery('(min-width: 375px)')
  const isMiddleScreen = useMediaQuery('(min-width: 640px)')

  useEffect(() => {
    const isMenuAnimated = getData('isHeaderAnimated')

    if (!isMenuAnimated) {
      setData('isHeaderAnimated', 'true')
    } else {
      // setAnimated(true)
    }

    return () => {
      removeData('isHeaderAnimated')
    }
  }, [])

  const Side = () => {
    return isAuthorized ? (
      <Button
        href={{
          pathname: routes.SERVICES,
          external: true
        }}
        as={Link}
        className={styles['section__button-link']}
        before={<Icon name="arrows/right/24" />}
      >
        {t('forms:buttons.services')}
      </Button>
    ) : (
      <>
        {isMiddleScreen && (
          <Button
            as={Link}
            href={routes.REGISTER}
            variant="light"
            className={styles['main__button']}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: t('forms:buttons.signup')
              }}
            />
          </Button>
        )}
        {isSmallScreen && (
          <Button
            className={styles['main__button']}
            as={Link}
            href={routes.LOGIN}
            before={<Icon name={'user/24'} />}
          >
            {t('forms:buttons.signin')}
          </Button>
        )}
      </>
    )
  }

  return (
    <header
      id={'header'}
      {...{ ['data-type']: type }}
      {...{ ['data-mobile-type']: shown ? 'light' : 'default' }}
      className={clsx(
        styles['header'],
        className,
        {
          [styles['header_animated']]: isAnimated
        },
        { [styles['header_with-shown-menu']]: shown }
      )}
    >
      {before && <div className={styles['header__side']}>{before}</div>}
      {children && <div className={styles['header__content']}>{children}</div>}
      <div className={styles['header__side']}>
        {after !== null ? after : <Side />}
      </div>
    </header>
  )
}
export default Header
