const getPathClear = (pathname: string) => {
  const localeRegexp = new RegExp('^/([a-z/]{2})(/.*)?$')
  const matchLocale = pathname.match(localeRegexp)

  if (matchLocale) {
    return matchLocale?.[2] ?? '/'
  }

  return pathname
}
export default getPathClear
