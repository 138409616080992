import dynamic from 'next/dynamic'
import { ComponentType, useEffect, useState } from 'react'

export interface UseDynamicSVGImportOptions {
  onCompleted?: (name: string, SvgIcon: ComponentType) => void
  onError?: (err: Error) => void
}

type SvgInHtml = HTMLElement & SVGElement

const useDynamicSVGImport = (
  name: string,
  options: UseDynamicSVGImportOptions = {}
) => {
  const [ImportedIconRef, setImportedIconRef] = useState<ComponentType>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error>()

  const { onCompleted, onError } = options

  useEffect(() => {
    setLoading(true)
    async function fetchIcon() {
      try {
        const Icon = dynamic(() => import(`../../public/images/${name}.svg`))

        setImportedIconRef(Icon)
        if (onCompleted) {
          onCompleted(name, Icon)
        }
      } catch (err: any) {
        if (onError) {
          onError?.(err as Error)
        }
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    fetchIcon()
  }, [name])

  return { error, loading, SvgIcon: ImportedIconRef }
}
export default useDynamicSVGImport
