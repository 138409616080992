import { useDispatch, useSelector } from 'react-redux'

import { toggleShown as actionToggleShown } from './actions'

export const useMenu = () => {
  const dispatch = useDispatch()

  const { shown } = useSelector(({ menu }) => menu)

  const toggleShown = props => {
    const { shown } = props
    const action = actionToggleShown({ shown })
    dispatch(action)
  }

  return {
    shown,
    toggleShown
  }
}
