import * as Sentry from '@sentry/browser'
import { FC, useEffect } from 'react'

const SentryManager: FC = () => {
  useEffect(() => {
    Sentry.init({
      // @ts-ignore
      dsn: process.env.SENTRY_DSN.replace(/\\(.)/gm, '$1'),
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: 1.0
    })
  }, [])
  return null
}
export default SentryManager
