import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'

import Icon from '@/components/Icon'

import useSessionStorage from '@/hooks/useSessionStorage'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './LogoIcon.module.scss'

interface LogoProps extends DefaultComponentProps {
  light?: boolean
}

const LogoIcon: FC<LogoProps> = ({ className = '', light = false }) => {
  const { getData } = useSessionStorage()
  const [isAnimated, setAnimated] = useState(false)

  useEffect(() => {
    const isHeaderAnimated = getData('isHeaderAnimated')
    if (isHeaderAnimated) {
      // setAnimated(true)
    }
  }, [])

  return (
    <div
      className={clsx(
        styles['logo-icon'],
        className,
        {
          [styles['logo-icon_light']]: light
        },
        { [styles['logo-icon_animated']]: isAnimated }
      )}
    >
      <Icon className={styles['logo-icon__icon']} name="getuniq/53" />
    </div>
  )
}

export default LogoIcon
